import { PatronBadgeSmall } from 'components/badges/PatronBadge';
import { useIsSupporter } from 'hooks/useIsSupporter';
import { UserInline, UserInlineProps } from 'ui/specific/inline/UserInline';

export const UserInlineContainer: React.FC<UserInlineProps> = ({ avatarSticker, ...props }) => {
  const isSupporter = useIsSupporter({ profileId: props.profile.id });

  if (isSupporter && !avatarSticker) {
    avatarSticker = <PatronBadgeSmall />;
  }

  return <UserInline avatarSticker={avatarSticker} {...props} />;
};
