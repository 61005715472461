import classnames from 'classnames';
import { OgDataPartsFragment } from 'generated/graphql';
import { useMemo } from 'react';
import styles from './Images.module.scss';

type Props = {
  images: OgDataPartsFragment['images'];
  variant?: 'full-size' | 'discreet';
};

type Values = {
  src: string;
  alt: string;
};

const Images = ({ images, variant = 'discreet' }: Props) => {
  if (!images || images.length === 0) return <div>Fallback image</div>;

  const { src, alt }: Values = useMemo(() => {
    const img = images.length > 0 ? images[0] : undefined;
    if (!img) return { src: '', alt: '' };
    let src = img.url ?? img.image ?? '';
    // Fix twitter resolution
    if (src?.includes('//pbs.twimg.com/profile_images')) {
      src = src.replace('_normal', '_200x200');
    }
    return { src, alt: img.alt ?? '' };
  }, [images]);

  if (!src) return null;

  return (
    <div className={classnames(styles.images, styles[variant])}>
      <img key={src} src={src} alt={alt} />
    </div>
  );
};

export default Images;
