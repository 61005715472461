import classNames from 'classnames';
import ReadingStateIcon from 'components/library/ReadingStateIcon';
import { BookPartsFragment, ProfilePartsFragment, ReadingState } from 'generated/graphql';
import { ReactNode } from 'react';
import { Avatar, Cover, Tag } from 'ui/generic';
import { statesData } from 'utils/readingStates';
import styles from './DropdownItems.module.scss';

type BookItemProps = {
  book: BookPartsFragment;
  noHover?: boolean;
  readingStates?: Array<Pick<ReadingState, 'id' | 'bookId' | 'status' | 'createdAt'>>;
  onClick?: () => void;
};

export const DropdownBookItem = ({ book, readingStates, noHover = false }: BookItemProps): JSX.Element => {
  const myReadingState = readingStates?.find((rs) => rs.bookId === book.id);
  return (
    <div className={classNames(styles.container, { [styles.noHover]: noHover })}>
      <div className={styles.image}>
        <Cover book={book} />
      </div>
      <div className={styles.bookInfo}>
        <span className={styles.top}>{book.title}</span>
        <span className={styles.bottom}>{book.authors.map((a) => a.name).join(', ')}</span>
      </div>
      {myReadingState && myReadingState.status !== 'NONE' && (
        <div className={styles.readingStatus}>
          <Tag icon={<ReadingStateIcon readingStatus={myReadingState.status} />}>
            {statesData[myReadingState.status].title === 'Currently reading'
              ? 'Reading'
              : statesData[myReadingState.status].title}
          </Tag>
        </div>
      )}
    </div>
  );
};

type ProfileItemProps = {
  profile: ProfilePartsFragment;
  onClick?: () => void;
};

export const DropdownProfileItem = ({ profile, onClick }: ProfileItemProps): JSX.Element => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div>
        <Avatar profile={profile} width={40} />
      </div>
      <div className={styles.profileInfo}>
        <span className={styles.top}>{profile.name}</span>
        <span className={styles.bottom}>@{profile.handle}</span>
      </div>
    </div>
  );
};

type InnerProps = {
  children: ReactNode;
};

export const DropdownInner = ({ children }: InnerProps): JSX.Element => {
  return (
    <div onClick={(e) => e.stopPropagation()} className={styles.dropdownInner}>
      {children}
    </div>
  );
};

export const BookOptionsHolder = ({ children }: InnerProps): JSX.Element => {
  return (
    <div className={classNames(styles.resultsContainer, styles.bookOptionsHolder)} role="book-options">
      {children}
    </div>
  );
};
