import Mention from '@tiptap/extension-mention';
import { mergeAttributes } from '@tiptap/react';

export const CustomMentionEditor = Mention.extend({
  addAttributes() {
    return {
      type: { default: '' },
      handle: { default: '' },
      label: { default: '' },
    };
  },
});

export const CustomMentionViewer = CustomMentionEditor.extend({
  parseHTML() {
    return [
      {
        tag: 'a[data-mention]',
        type: 'data-type',
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    let href = '';
    if (node.attrs.type === 'Profile') {
      href = `/${node.attrs.handle}`;
    } else if (node.attrs.type === 'Book') {
      href = `/book/${node.attrs.slug}`;
    }
    return [
      'a',
      mergeAttributes(
        {
          'data-mention': '',
          href: href,
        },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      this.options.renderLabel({
        options: this.options,
        node,
      }),
    ];
  },
});
