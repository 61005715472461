import { OgDataPartsFragment } from 'generated/graphql';
import { MouseEvent } from 'react';
import { CloseIcon } from 'ui/icons';
import Images from './Images';
import { Info } from './Info';
import styles from './LinkPreview.module.scss';
import { getType, OgType } from './utils';
import Video from './Video';

type Props = {
  ogData: OgDataPartsFragment;
  onClear?: () => void;
};

export const LinkPreview = ({ ogData, onClear }: Props) => {
  if (!ogData || !ogData.url) return null;

  const type = getType(ogData);

  const handleClear = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onClear) onClear();
  };

  if (type === 'hidden') return null;

  return (
    <div className={styles.container}>
      {onClear && (
        <button className={styles.crossButton} onClick={handleClear}>
          <CloseIcon />
        </button>
      )}
      <a
        className={styles.link}
        href={ogData.url}
        target={type === 'literal' ? '_self' : '_blank'}
        rel="noopener noreferrer"
      >
        <Inner ogData={ogData} type={type} />
      </a>
    </div>
  );
};

type InnerProps = {
  ogData: OgDataPartsFragment;
  type: OgType;
};
const Inner = ({ ogData, type }: InnerProps) => {
  if (type === 'image') {
    return (
      <div className="flex">
        <Images images={ogData.images} />
        <Info ogData={ogData} />
      </div>
    );
  }
  if (type === 'literal') {
    return (
      <>
        <Info ogData={ogData} />
        <Images images={ogData.images} variant="full-size" />
      </>
    );
  }
  if (type === 'video' || type === 'youtube' || type === 'vimeo') {
    return (
      <>
        <Info ogData={ogData} />
        <Video videos={ogData.videos} type={type} />
      </>
    );
  }
  if (type === 'twitter') {
    return (
      <>
        <div className="flex">
          <Images images={ogData.images} />
          <Info ogData={ogData} />
        </div>
      </>
    );
  }
  if (type === 'tiktok') {
    return (
      <>
        <div className="flex">
          <Images images={ogData.images} />
          <Info ogData={ogData} />
        </div>
      </>
    );
  }
  return (
    <>
      <Info ogData={ogData} />
    </>
  );
};
