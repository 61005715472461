import { OgDataPartsFragment } from 'generated/graphql';

export type OgVideoType = 'video' | 'vimeo' | 'youtube';
export type OgType = 'image' | 'literal' | 'twitter' | 'tiktok' | 'fallback' | 'hidden' | OgVideoType;

export function getType(ogData: OgDataPartsFragment): OgType {
  if (ogData.url?.includes('https://literal.club')) return 'literal';
  if (ogData.type === 'video.other' && ogData.site_name === 'YouTube') return 'youtube';
  if (ogData.type === 'video.other' && ogData.site_name === 'Vimeo') return 'vimeo';
  if (ogData.site_name === 'Twitter') return 'twitter';
  if (ogData.url?.includes('https://www.tiktok.com')) return 'tiktok';
  if (ogData.videos && ogData.videos.length > 0) return 'video';
  if (ogData.images && ogData.images.length > 0) return 'image';
  if (ogData.url?.includes('https://www.goodreads.com/')) return 'hidden';
  return 'fallback';
}
