import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';
import styles from './Inline.module.scss';

type Props = {
  left?: string | React.ReactNode;
  top: string | React.ReactNode;
  bottom: string | React.ReactNode;
  right?: string | React.ReactNode | null;
  below?: string | React.ReactNode;
  topMaxLines?: number;
  bottomMaxLines?: number;
  border?: boolean;
  href?: string;
  as?: string;
};

export const Inline = (props: Props) => {
  if (props.href) {
    return (
      <Link href={props.href} as={props.as}>
        <a
          className={classNames('inline-small-container', styles.container, {
            [styles.withBorder]: props.border,
          })}
        >
          <InlineInner {...props} />
          {props.below && props.below}
        </a>
      </Link>
    );
  }
  return (
    <div
      className={classNames('inline-small-container', styles.container, {
        [styles.withBorder]: props.border,
      })}
    >
      <InlineInner {...props} />
      {props.below && props.below}
    </div>
  );
};

const InlineInner = ({
  left,
  top,
  bottom,
  topMaxLines = 1,
  bottomMaxLines = 1,
  right,
}: Props): JSX.Element => {
  return (
    <div className="flex items-center justify-between">
      <div className={styles.left}>{left && left}</div>
      <div className={styles.center}>
        <span className={styles.top} style={{ WebkitLineClamp: topMaxLines }}>
          {top}
        </span>
        <span className={styles.bottom} style={{ WebkitLineClamp: bottomMaxLines }}>
          {bottom}
        </span>
      </div>
      {right && <div className={styles.right}>{right}</div>}
    </div>
  );
};
