/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link } from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import { Content, Editor, EditorContent, JSONContent, ReactRenderer, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { BookPartsFragment, ProfilePartsFragment } from 'generated/graphql';
import React, { ReactNode, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import tippy, { Instance } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { LinkPreviewState, TextLinkPreview } from 'ui/specific/linkPreview/TextLinkPreview';
import { CustomMentionEditor } from './MentionExtension';
import { MentionList } from './MentionList';
import styles from './RichTextEditor.module.scss';
import { RichTextError } from './RichTextError';

export type RichTextData = { text: string; textJson: JSONContent; hideLinkPreview?: boolean };

export type MentionSuggestionType = ProfilePartsFragment[] | BookPartsFragment[];

type PropsRef = {
  editor: Editor | null;
};

type Props = {
  // if you don't pass a getSugggestions promise function mentions are disabled
  getSuggestions?: (variables: string) => Promise<MentionSuggestionType>;
  placeholder: string;
  setValue: (value: RichTextData) => void;
  submitted?: boolean;
  content?: Content;
  avatar?: ReactNode;
  autoFocus?: boolean;
  replyMentions?: ProfilePartsFragment[];
  linkPreviewState?: LinkPreviewState;
};

const _RichTextEditor: React.ForwardRefRenderFunction<PropsRef, Props> = (props: Props, ref): JSX.Element => {
  return (
    <ErrorBoundary FallbackComponent={RichTextError}>
      <RichTextEditorInner ref={ref} {...props} />
    </ErrorBoundary>
  );
};

export const RichTextEditor = React.forwardRef(_RichTextEditor);

const _RichTextEditorInner: React.ForwardRefRenderFunction<PropsRef, Props> = (
  {
    getSuggestions,
    placeholder = '',
    setValue,
    submitted,
    content = '',
    avatar,
    autoFocus = false,
    replyMentions = [],
    linkPreviewState,
  }: Props,
  forwardedRef
): JSX.Element | null => {
  const ref = useRef<HTMLDivElement>(null);
  const styleClass = avatar ? styles.avatarContainer : styles.container;

  const editor = useEditor(
    {
      parseOptions: {
        preserveWhitespace: 'full',
      },
      editorProps: {
        attributes: {
          class: styleClass,
        },
      },
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder,
          emptyEditorClass: 'is-editor-empty',
        }),
        Link.configure({
          HTMLAttributes: {
            class: styles.link,
          },
        }),
        ...(getSuggestions
          ? [
              CustomMentionEditor.configure({
                HTMLAttributes: { class: styles.mention },
                suggestion: {
                  items: async (query): Promise<MentionSuggestionType> => {
                    if (query.query.length < 1) return [];
                    return getSuggestions(query.query);
                  },

                  render: () => {
                    let reactRenderer: ReactRenderer;
                    let popup: Instance[];
                    return {
                      onStart: (props) => {
                        if (!ref.current) return;
                        // todo: broken package - try to fix later
                        reactRenderer = new ReactRenderer(MentionList as any, {
                          props,
                          editor: props.editor as Editor,
                        });
                        popup = tippy([ref.current], {
                          theme: 'literal',
                          getReferenceClientRect: props.clientRect,
                          appendTo: () => document.body,
                          content: reactRenderer.element,
                          showOnCreate: false,
                          interactive: true,
                          trigger: 'manual',
                          placement: 'bottom-start',
                          arrow: true,
                          offset: [0, 15],
                        });
                      },
                      onUpdate(props) {
                        reactRenderer.updateProps(props);
                        popup[0].setProps({
                          getReferenceClientRect: props.clientRect,
                        });
                        if (props.items.length > 0) popup[0].show();
                        if (props.items.length === 0) popup[0].hide();
                      },
                      onKeyDown(props) {
                        if (props.event.key === 'Escape') {
                          popup[0].hide();
                          return true;
                        }
                        return (reactRenderer.ref as any)?.onKeyDown(props);
                      },
                      onExit() {
                        popup[0] && popup[0].destroy();
                        reactRenderer.destroy();
                      },
                    };
                  },
                },
              }),
            ]
          : []),
      ],
      content: content,
      onUpdate({ editor }) {
        const json = editor.getJSON();
        const text = editor.getText();
        setValue({ text: text, textJson: json, hideLinkPreview: true });
      },
      onCreate({ editor }) {
        if (content) {
          // In edit mode we want the save button to appear, so we set the content initially
          const json = editor.getJSON();
          const text = editor.getText();
          setValue({ text: text, textJson: json, hideLinkPreview: true });
        } else if (replyMentions && replyMentions.length > 0) {
          editor.commands.setContent(
            replyMentions
              .map((p) => [
                {
                  type: 'mention',
                  attrs: { handle: p.handle, label: p.handle, type: 'Profile' },
                },
                {
                  type: 'text',
                  text: ' ',
                },
              ])
              .flat()
          );
        }
        if (autoFocus && ref.current) {
          editor.commands.focus('end');
        }
      },
    },
    [submitted]
  );

  React.useImperativeHandle(forwardedRef, () => ({ editor }));

  if (!editor) return null;

  return (
    <div style={{ position: 'relative' }} ref={ref}>
      {avatar && <div className={styles.avatar}>{avatar}</div>}
      <EditorContent editor={editor} />
      {linkPreviewState && <TextLinkPreview text={editor.getText()} state={linkPreviewState} />}
    </div>
  );
};

const RichTextEditorInner = React.forwardRef(_RichTextEditorInner);

export type RichTextEditorRef = React.ElementRef<typeof RichTextEditor>;
