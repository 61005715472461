import { BookPartsFragment, ProfilePartsFragment } from 'generated/graphql';
import { useMemo } from 'react';
import { Avatar, Cover } from 'ui/generic';
import { Inline } from './Inline';
import styles from './UserInline.module.scss';

type Props = {
  profile: ProfilePartsFragment;
  avatarSticker?: JSX.Element;
  right?: string | React.ReactNode | null;
  border?: boolean;
  link?: boolean;
  bottomContent?: 'handle' | 'bio' | string;
  books?: BookPartsFragment[]; // Used to showcase books in common
};

/**
 * Consider using UserInlineContainer as a wrapper for this component
 * to provide the profile badges as well
 */
export const UserInline = ({
  profile,
  avatarSticker,
  bottomContent = 'handle',
  link = false,
  books,
  ...rest
}: Props): JSX.Element => {
  let bottomContentString = useMemo(() => {
    if (bottomContent === 'handle') return `@${profile.handle}`;
    else if (bottomContent === 'bio') return profile.bio || undefined;
    else if (bottomContent) return bottomContent;
  }, [bottomContent, profile]);

  return (
    <Inline
      left={<Avatar width={40} profile={profile} avatarSticker={avatarSticker} />}
      top={profile.name}
      bottom={bottomContentString}
      below={books && <HorizontalBookList books={books} />}
      href={link ? '/[handle]' : undefined}
      as={link ? `/${profile.handle}` : undefined}
      {...rest}
    />
  );
};

type HorizontalBookListProps = {
  books: BookPartsFragment[];
};

const HorizontalBookList = ({ books }: HorizontalBookListProps) => {
  if (books.length === 0) return null;
  return (
    <div className={styles.track}>
      <div className="flex items-end">
        {books.map((book) => (
          <div key={book.id} className={styles.cover}>
            <Cover book={book} assetSize="100" />
          </div>
        ))}
      </div>
    </div>
  );
};

export type UserInlineProps = Props;
