import { SuggestionProps } from '@tiptap/suggestion';
import { DropdownBookItem } from 'components/layout/dropdownSearch/DropdownItems';
import { UserInlineContainer } from 'containers/inline/UserInlineContainer';
import { BookPartsFragment, ProfilePartsFragment } from 'generated/graphql';
import { forwardRef } from 'react';
import { ActionItemType, ActionList } from 'ui/specific/actionList';
import { isBook, isProfile } from 'utils/typeGuards';

interface SuggestionPropsCustom extends SuggestionProps {
  items: ProfilePartsFragment[] | BookPartsFragment[];
}

export const MentionList = forwardRef<unknown, SuggestionPropsCustom>((props, ref) => {
  const selectItem = (index: number) => {
    const item = props.items[index];
    if (isProfile(item)) {
      props.command({ label: item.handle, type: 'Profile', handle: item.handle });
    }
    if (isBook(item)) {
      props.command({ label: item.slug, type: 'Book', slug: item.slug });
    }
  };

  const actionItems: ActionItemType[] = props.items.map((item, index) => {
    return {
      render: isProfile(item) ? (
        <div className="my-2">
          <UserInlineContainer profile={item} />
        </div>
      ) : (
        <DropdownBookItem book={item} />
      ),
      onClick: () => selectItem(index),
    };
  });

  if (props.items.length < 1) return null;

  return <ActionList items={actionItems} />;
});

MentionList.displayName = 'MentionList';
