import classNames from 'classnames';
import { OgDataPartsFragment } from 'generated/graphql';
import styles from './Info.module.scss';

type Props = {
  ogData: OgDataPartsFragment;
};

export const Info = ({ ogData }: Props) => {
  return (
    <div className={styles.info}>
      <h4 className={classNames('uiBase', styles.title)}>{ogData.title}</h4>
      <p className={classNames('uiDiscreet', styles.description)}>{ogData.description}</p>
      {ogData.url && (
        <p className="uiDiscreet textDiscreet mt-1">{new URL(ogData.url).hostname.replace('www.', '')}</p>
      )}
    </div>
  );
};
