import { OgDataPartsFragment, useOgDataLazyQuery } from 'generated/graphql';
import linkIt from 'linkify-it';
import { useEffect, useRef, useState } from 'react';
import { LinkPreview } from 'ui/specific/linkPreview/LinkPreview';

const linkify = linkIt();

export type LinkPreviewState = {
  hideLinkPreview: boolean;
  setHideLinkPreview?: (val: boolean) => void;
};

type TextLinkProps = {
  text: string;
  state: LinkPreviewState;
};

export const TextLinkPreview = ({ text, state }: TextLinkProps) => {
  const prevLink = useRef('');
  const [ogDataQuery] = useOgDataLazyQuery();
  const [linkOgData, setLinkOgData] = useState<OgDataPartsFragment | null>(null);

  useEffect(() => {
    if (!state) return;
    const matches = linkify.match(text);
    if (matches) {
      const link = matches[matches.length - 1];
      if (link.url !== prevLink.current) {
        prevLink.current = link.url;
        setLinkOgData(null);
        if (state.setHideLinkPreview) state.setHideLinkPreview(false);
        loadOgData(link.url);
      }
    } else {
      setLinkOgData(null);
      prevLink.current = '';
    }
  }, [text, state]);

  const loadOgData = async (link: string | null) => {
    if (link) {
      const result = await ogDataQuery({ variables: { url: link } });
      if (result.data?.ogData) {
        setLinkOgData(result.data.ogData);
        return;
      }
    }
    setLinkOgData(null);
  };

  if (!linkOgData || state.hideLinkPreview) return null;

  const handleClear = state.setHideLinkPreview ? () => state.setHideLinkPreview?.(true) : undefined;

  return (
    <div className="mt-4">
      <LinkPreview ogData={linkOgData} onClear={handleClear} />
    </div>
  );
};
