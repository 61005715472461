import { OgDataPartsFragment } from 'generated/graphql';
import { OgVideoType } from './utils';
import styles from './Video.module.scss';

type Props = {
  videos: OgDataPartsFragment['videos'];
  type: OgVideoType;
};

const Video = ({ videos, type }: Props) => {
  if (!videos || videos.length === 0) return null;
  const video = videos[0];

  if (type === 'youtube') {
    return (
      <div className={styles.video}>
        {video.url && (
          <iframe
            className={styles.iframe}
            src={`https://www.youtube.com/embed/${video.url.replace(
              'https://www.youtube.com/watch?v=',
              ''
            )}?modestbranding=1`}
            frameBorder="0"
            allowFullScreen
          />
        )}
      </div>
    );
  }
  if (type === 'vimeo') {
    return (
      <div className={styles.video}>
        <iframe
          className={styles.iframe}
          src={(video.url || video.video || '').replace('autoplay=1', 'autoplay=0')}
          allowFullScreen
        />
      </div>
    );
  }
  return null;
};

export default Video;
